import {BaseAction} from '../common/BaseAction'
import {bindActionCreators} from 'redux'
import {SelectedProductDTO, ProductDTO} from 'grilnica-store-share'
import {OptionCategoryTypeMap} from '../../types/product/OptionCategoryTypeMap'
import {OptionCategoryMap} from '../../types/product/OptionCategoryMap'
import {SelectedOptionsMap} from '../../types/product/SelectedOptionsMap'
import {ProductVariantMap} from '../../types/product/ProductVariantMap'
import {RestrictionsMap} from '../../types/product/RestrictionsMap'
import {CountSelectedOptionsMap} from '../../types/product/CountSelectedOptionsMap'
import {ExtraOptionsMap} from '../../types/product/ExtraOptionsMap'
import {OptionMap} from '../../types/product/OptionMap'
import OptionProductMap from '../../types/product/OptionProductMap'

export interface ProductState {
  selectedProduct: SelectedProductDTO
  optionCategoryTypeMap: OptionCategoryTypeMap
  optionCategoryMap: OptionCategoryMap
  selectedOptionsMap: SelectedOptionsMap
  productVariantMap: ProductVariantMap
  restrictionsMap: RestrictionsMap
  countSelectedOptionsMap: CountSelectedOptionsMap
  extraOptionsMap: ExtraOptionsMap
  optionMap: OptionMap
  sumPriceOptions: number
  isShowExtraOptionsAlertPopover: boolean
  productPopoverText: string
  optionProductMap: OptionProductMap
  openedProductInModal: ProductDTO | null
  editProductIndex: number | null
  isOpenSearchModalTerminal: boolean
}

export enum ProductActionsTypes {
  SELECT_PRODUCT = 'product/SELECT_PRODUCT',
  CLEAR_PRODUCT = 'product/CLEAR_PRODUCT',
  TOGGLE_OPTION_REQUEST = 'product/TOGGLE_OPTION_REQUEST',
  TOGGLE_OPTION_SUCCESS = 'product/TOGGLE_OPTION_SUCCESS',
  SET_SUPPORTS_MAPS = 'product/SET_SUPPORTS_MAPS',
  ADD_COUNT_SELECTED_PRODUCT = 'product/ADD_COUNT_SELECTED_PRODUCT',
  SUBTRACT_COUNT_SELECTED_PRODUCT = 'product/SUBTRACT_COUNT_SELECTED_PRODUCT',
  SET_SUM_PRICE_OPTIONS = 'product/SET_SUM_PRICE_OPTIONS',
  TOGGLE_IS_SHOW_EXTRA_OPTIONS_ALERT_POPOVER = 'product/TOGGLE_IS_SHOW_EXTRA_OPTIONS_ALERT_POPOVER',
  SET_PRODUCT_POPOVER_TEXT = 'product/SET_PRODUCT_POPOVER_TEXT',
  SET_OPENED_PRODUCT_IN_MODAL = 'SET_OPENED_PRODUCT_IN_MODAL',
  SET_OPENED_SEARCH_MODAL_TERMINAL = 'SET_OPENED_SEARCH_MODAL_TERMINAL',
}

const INITIAL_STATE: ProductState = {
  selectedProduct: null,
  optionCategoryTypeMap: null,
  optionCategoryMap: null,
  selectedOptionsMap: null,
  productVariantMap: null,
  restrictionsMap: null,
  countSelectedOptionsMap: null,
  extraOptionsMap: {},
  optionMap: null,
  sumPriceOptions: 0,
  isShowExtraOptionsAlertPopover: false,
  productPopoverText: null,
  optionProductMap: null,
  openedProductInModal: null,
  editProductIndex: null,
  isOpenSearchModalTerminal: false,
}

export const Actions = {
  selectProduct: (product: SelectedProductDTO): BaseAction => ({
    type: ProductActionsTypes.SELECT_PRODUCT,
    payload: product,
  }),

  clearProduct: (): BaseAction => ({
    type: ProductActionsTypes.CLEAR_PRODUCT,
  }),

  toggleOptionRequest: (
    optionCategoryId: string,
    optionId: string,
    isChecked: boolean,
    isOptional: boolean,
  ): BaseAction => ({
    type: ProductActionsTypes.TOGGLE_OPTION_REQUEST,
    payload: {optionCategoryId, optionId, isChecked, isOptional},
  }),

  toggleOptionSuccess: (
    selectedOptionsMap: SelectedOptionsMap,
    countSelectedOptionsMap: CountSelectedOptionsMap,
    restrictionsMap: RestrictionsMap,
    extraOptionsMap: ExtraOptionsMap,
  ): BaseAction => ({
    type: ProductActionsTypes.TOGGLE_OPTION_SUCCESS,
    payload: {
      selectedOptionsMap,
      countSelectedOptionsMap,
      restrictionsMap,
      extraOptionsMap,
    },
  }),

  setSupportsMaps: (
    optionCategoryTypeMap: OptionCategoryTypeMap,
    optionCategoryMap: OptionCategoryMap,
    selectedOptionsMap: SelectedOptionsMap,
    restrictionsMap: RestrictionsMap,
    countSelectedOptionsMap: CountSelectedOptionsMap,
    productVariantMap: ProductVariantMap,
    optionMap: OptionMap,
    optionProductMap: OptionProductMap,
  ): BaseAction => ({
    type: ProductActionsTypes.SET_SUPPORTS_MAPS,
    payload: {
      optionCategoryTypeMap,
      optionCategoryMap,
      selectedOptionsMap,
      restrictionsMap,
      countSelectedOptionsMap,
      productVariantMap,
      optionMap,
      optionProductMap,
    },
  }),

  addCountSelectedProduct: (): BaseAction => ({
    type: ProductActionsTypes.ADD_COUNT_SELECTED_PRODUCT,
  }),

  subtractSelectedProduct: (): BaseAction => ({
    type: ProductActionsTypes.SUBTRACT_COUNT_SELECTED_PRODUCT,
  }),

  setSumPriceOptions: (sumPriceOptions: number): BaseAction => ({
    type: ProductActionsTypes.SET_SUM_PRICE_OPTIONS,
    payload: sumPriceOptions,
  }),

  toggleExtraOptionAlertPopover: (isShow: boolean): BaseAction => ({
    type: ProductActionsTypes.TOGGLE_IS_SHOW_EXTRA_OPTIONS_ALERT_POPOVER,
    payload: isShow,
  }),

  setProductPopoverText: (text: string): BaseAction => ({
    type: ProductActionsTypes.SET_PRODUCT_POPOVER_TEXT,
    payload: text,
  }),

  setOpenProductInModal: (product: ProductDTO, editProductIndex: number | null): BaseAction => ({
    type: ProductActionsTypes.SET_OPENED_PRODUCT_IN_MODAL,
    payload: {product, editProductIndex},
  }),

  setOpenSearchModalTerminal: (isOpened): BaseAction => ({
    type: ProductActionsTypes.SET_OPENED_SEARCH_MODAL_TERMINAL,
    payload: isOpened,
  }),
}

export type ProductActionsType = typeof Actions

export function bindProductActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export default function product(
  state: ProductState = INITIAL_STATE,
  action: BaseAction,
): ProductState {
  switch (action.type) {
    case ProductActionsTypes.SELECT_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload,
      }

    case ProductActionsTypes.CLEAR_PRODUCT:
      return {
        ...state,
        ...INITIAL_STATE,
        productPopoverText: state.productPopoverText,
      }

    case ProductActionsTypes.TOGGLE_OPTION_SUCCESS: {
      return {
        ...state,
        selectedOptionsMap: action.payload.selectedOptionsMap,
        countSelectedOptionsMap: action.payload.countSelectedOptionsMap,
        restrictionsMap: action.payload.restrictionsMap
          ? action.payload.restrictionsMap
          : {...state.restrictionsMap},
        extraOptionsMap: action.payload.extraOptionsMap
          ? action.payload.extraOptionsMap
          : {...state.extraOptionsMap},
      }
    }

    case ProductActionsTypes.SET_SUPPORTS_MAPS: {
      return {
        ...state,
        optionCategoryTypeMap: action.payload.optionCategoryTypeMap,
        optionCategoryMap: action.payload.optionCategoryMap,
        selectedOptionsMap: action.payload.selectedOptionsMap,
        restrictionsMap: action.payload.restrictionsMap,
        countSelectedOptionsMap: action.payload.countSelectedOptionsMap,
        productVariantMap: action.payload.productVariantMap,
        optionMap: action.payload.optionMap,
        extraOptionsMap: {},
        optionProductMap: action.payload.optionProductMap,
      }
    }

    case ProductActionsTypes.ADD_COUNT_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          count:
            state.selectedProduct.count < 99
              ? state.selectedProduct.count + 1
              : state.selectedProduct.count,
        },
      }
    }

    case ProductActionsTypes.SUBTRACT_COUNT_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          count:
            state.selectedProduct.count > 1
              ? state.selectedProduct.count - 1
              : state.selectedProduct.count,
        },
      }
    }

    case ProductActionsTypes.SET_SUM_PRICE_OPTIONS: {
      return {
        ...state,
        sumPriceOptions: action.payload,
      }
    }

    case ProductActionsTypes.TOGGLE_IS_SHOW_EXTRA_OPTIONS_ALERT_POPOVER: {
      return {
        ...state,
        isShowExtraOptionsAlertPopover: action.payload,
      }
    }

    case ProductActionsTypes.SET_PRODUCT_POPOVER_TEXT: {
      return {
        ...state,
        productPopoverText: action.payload,
      }
    }

    case ProductActionsTypes.SET_OPENED_PRODUCT_IN_MODAL: {
      return {
        ...state,
        openedProductInModal: action.payload.product,
        editProductIndex: action.payload.editProductIndex,
      }
    }

    case ProductActionsTypes.SET_OPENED_SEARCH_MODAL_TERMINAL: {
      return {
        ...state,
        isOpenSearchModalTerminal: action.payload,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
