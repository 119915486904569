import React, {useEffect, useMemo, useState} from 'react'
import classNames from 'classnames'
import {ButtonIcon} from 'grilnica-reactstrap'
import {arrowLeftOutlineSVG, arrowRightOutlineSVG} from '../../../../components/svg/SystemIcons'
import {isEmpty} from 'grilnica-share'

interface ScrollableContainerTerminalProps {
  id: string
  itemSize: number
  countItems: number
  classNameBody?: string
  children: any
}

const ScrollableContainerTerminal: React.FC<ScrollableContainerTerminalProps> = ({
  id,
  itemSize,
  countItems,
  classNameBody,
  children,
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(null)
  const [scrollPos, setScrollPos] = useState<number>(null)
  const [scrollWidth, setScrollWidth] = useState<number>(null)
  const [isShowRightArrow, setIsShowRightArrow] = useState<boolean>(false)
  const [isShowLeftArrow, setIsShowLeftArrow] = useState<boolean>(false)
  const [isShowButton, setIsShowButton] = useState<boolean>(false)

  useEffect(() => {
    const element = document.getElementById('container-nav-' + id)

    element.addEventListener('mouseenter', () => {
      setIsShowButton(true)
    })
    element.addEventListener('mouseleave', () => {
      setIsShowButton(false)
    })

    setContainerWidth(element.clientWidth)
    setScrollWidth(element.scrollWidth)
    setScrollPos(element.scrollLeft)
    if (element.scrollLeft >= itemSize) {
      setIsShowLeftArrow(true)
    } else {
      setIsShowLeftArrow(false)
    }
    if (element.scrollLeft + element.clientWidth !== element.scrollWidth) {
      setIsShowRightArrow(true)
    } else {
      setIsShowRightArrow(false)
    }
  }, [id])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={classNames('scrollable-container-terminal')}>
        <div className={'d-flex'}>
          {isShowLeftArrow && (
            <div className={'rp-arrow-l'}>
              {isShowButton && (
                <ButtonIcon
                  iconPath={arrowLeftOutlineSVG}
                  title={'Назад'}
                  onClick={() => {
                    if (scrollPos && scrollPos > 0) {
                      let activeElement = null
                      if (scrollPos < itemSize) {
                        activeElement = document.getElementById(id + '-' + 0)
                      } else {
                        let index = Math.floor(scrollPos / itemSize)
                        if (scrollPos - itemSize * index < itemSize / 2) {
                          index = index - 1
                        }
                        activeElement = document.getElementById(id + '-' + (index > 0 ? index : 0))
                      }
                      if (activeElement) {
                        activeElement.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                          inline: 'start',
                        })
                      }
                    }
                  }}
                />
              )}
            </div>
          )}
          <div
            id={'container-nav-' + id}
            onScroll={(e: any) => {
              const posLeft = e.target.scrollLeft
              setScrollPos(posLeft)
              if (posLeft > 0) {
                setIsShowLeftArrow(true)
              } else {
                setIsShowLeftArrow(false)
              }
              if (posLeft + containerWidth !== scrollWidth) {
                setIsShowRightArrow(true)
              } else {
                setIsShowRightArrow(false)
              }
            }}
            className={classNames('scrollable-container-body-terminal', classNameBody)}>
            {children}
          </div>
          {isShowRightArrow && (
            <div className={'rp-arrow-r'}>
              {isShowButton && (
                <ButtonIcon
                  iconPath={arrowRightOutlineSVG}
                  title={'Вперёд'}
                  onClick={() => {
                    if (!isEmpty(scrollPos)) {
                      let activeElement = null
                      if (scrollPos + containerWidth > scrollWidth - itemSize) {
                        activeElement = document.getElementById(id + '-' + countItems)
                      } else {
                        let index: number = Math.floor(scrollPos / itemSize)
                        let addIndex: number = Math.floor(containerWidth / itemSize)
                        if (addIndex === 2) {
                          addIndex = addIndex - 1
                        } else if (addIndex < 1) {
                          addIndex += 1
                        }
                        index = index + addIndex
                        activeElement = document.getElementById(id + '-' + (index <= 0 ? 1 : index))
                      }
                      if (activeElement) {
                        activeElement.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                          inline: 'start',
                        })
                      }
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }, [
    id,
    containerWidth,
    id,
    isShowButton,
    isShowLeftArrow,
    isShowRightArrow,
    countItems,
    children,
    scrollPos,
    scrollWidth,
    classNameBody,
  ])

  return <>{renderContent}</>
}

export {ScrollableContainerTerminal}
