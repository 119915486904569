import {ProductDTO, ProductVariantDTO, WeightMapDto} from 'grilnica-store-share'
import {isEmpty} from 'grilnica-share'
import {getMinDefaultWeight} from '../../../../../../../../../utils/menu'

function getPricesAndWeight(product: ProductDTO, weightMap: WeightMapDto) {
  if (!product) {
    return {}
  }
  let price: number = product.price
  let salePrice: number = null
  let minPrice: number = null
  if (product.productVariants?.length > 0) {
    const productVariants: ProductVariantDTO[] = product.productVariants
    minPrice = productVariants[0].price
    for (let itemPV of productVariants) {
      if (minPrice > itemPV.price) {
        minPrice = itemPV.price
      }
      if (!isEmpty(itemPV.salePrice) && minPrice > itemPV.salePrice) {
        minPrice = itemPV.salePrice
      }
    }
  } else {
    salePrice = product.salePrice ? price - (price - product.salePrice) : null
  }
  let weight: string = null
  if (product) {
    weight = getMinDefaultWeight(product, weightMap)
  }
  return {price, salePrice, minPrice, weight}
}

function getPriceName(salePrice: number, minPrice: number, price: number, isAddPrefix: boolean) {
  return salePrice ? salePrice : minPrice ? `${isAddPrefix ? 'от ' : ''}${minPrice}` : price
}

export {getPricesAndWeight, getPriceName}
