import {ProductDTO, ProductMap} from 'grilnica-store-share'
import {IsShowRestaurantByProductMap} from '../../../../types/restaurantProduct/IsShowRestaurantByProductMap'
import {isIncludeSubstring} from 'grilnica-share'

export const getFoundedProducts = (
  productMap: ProductMap,
  searchProductValue: string,
  isShowRestaurantByProductMap: IsShowRestaurantByProductMap,
  isTerminal?: boolean,
): ProductDTO[] => {
  const allFoundProducts: ProductDTO[] = []
  for (let key in productMap) {
    if (!key.includes('-actions')) {
      let foundProducts: ProductDTO[] = productMap[key].filter(
        (item) =>
          !allFoundProducts.find((p) => p.productId === item.productId) && // Исключаем уже добавленные товары
          (isShowRestaurantByProductMap[item.productId] !== undefined || isTerminal) &&
          (item.price || item.salePrice || item.isShowWithZeroPrice) &&
          !item.isHideInApp &&
          (isIncludeSubstring(item.name, searchProductValue) ||
            isIncludeSubstring(item.menuCategoryName, searchProductValue)),
      )
      for (let foundProduct of foundProducts) {
        foundProduct.menuCategoryId = key
      }
      allFoundProducts.push(...foundProducts)
    }
  }
  return allFoundProducts
}
