import {bindActionCreators} from 'redux'
import {BaseAction} from '../common/BaseAction'
import {ProductDTO, SelectedProductDTO} from 'grilnica-store-share'
import {ProductItemState} from '../../view/site/containers/menu/components/product/productList/productItem/ducks/ProductItemDuck'

export interface BasketState {
  selectedProducts: SelectedProductDTO[]
  isOpenBasketModalTerminal: boolean
  isOpenClearBasketModal: boolean
  isOpenAddCouponModalTerminal: boolean
  countProductInBasket: number
  isOpenDeleteProductFromBasketModal: boolean
  deleteProductIndex: number
  editProductIndex: number
  priceFull: number
  saleFull: number
  deliverySale: number
  isLoadCheckAction: boolean
  isUpdateCheckActions: boolean
  isErrorUpdateCheckActions: boolean
  coupon: string
  isSuccessPersonalCoupon: boolean
}

export enum BasketActionsTypes {
  ADD_PRODUCT_WITH_OPTIONS_IN_BASKET_REQUEST = 'basket/ADD_PRODUCT_WITH_OPTIONS_IN_BASKET_REQUEST',
  CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_REQUEST = 'basket/CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_REQUEST',
  CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_SUCCESS = 'basket/CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_SUCCESS',
  TOGGLE_CLEAR_BASKET_MODAL = 'basket/TOGGLE_CLEAR_BASKET_MODAL', //TODO:: +-
  TOGGLE_OPEN_BASKET_MODAL_TERMINAL = 'basket/TOGGLE_OPEN_BASKET_MODAL_TERMINAL', //TODO:: +-
  TOGGLE_OPEN_ADD_COUPON_MODAL_TERMINAL = 'basket/TOGGLE_OPEN_ADD_COUPON_MODAL_TERMINAL',
  CLEAR_BASKET_REQUEST = 'basket/CLEAR_BASKET_REQUEST',
  CLEAR_BASKET_SUCCESS = 'basket/CLEAR_BASKET_SUCCESS',
  ADD_PRODUCT_WITHOUT_OPTIONS_IN_BASKET_REQUEST = 'basket/ADD_PRODUCT_WITHOUT_OPTIONS_IN_BASKET_REQUEST',
  ADD_PRODUCT_WITH_ONE_OPTION_IN_BASKET_REQUEST = 'basket/ADD_PRODUCT_WITH_ONE_OPTION_IN_BASKET_REQUEST',
  SET_SELECTED_PRODUCTS = 'basket/SET_SELECTED_PRODUCTS',
  SET_COUNT_PRODUCT_IN_BASKET = 'basket/SET_COUNT_PRODUCT_IN_BASKET',
  DELETE_PRODUCT_FROM_BASKET_REQUEST = 'basket/DELETE_PRODUCT_FROM_BASKET_REQUEST',
  TOGGLE_DELETE_PRODUCT_FROM_BASKET_MODAL = 'basket/TOGGLE_DELETE_PRODUCT_FROM_BASKET_MODAL', //TODO:: +-
  OPEN_PRODUCT_SCREEN_FOR_EDIT_REQUEST = 'basket/OPEN_PRODUCT_SCREEN_FOR_EDIT_REQUEST', //TODO:: +-
  SET_EDIT_PRODUCT_INDEX = 'basket/SET_EDIT_PRODUCT_INDEX',
  SET_PRICE_FULL = 'basket/SET_PRICE_FULL',
  ADD_COMBO_IN_BASKET_REQUEST = 'basket/ADD_COMBO_IN_BASKET_REQUEST',
  SET_SALE_FULL = 'basket/SET_SALE_FULL',
  SET_DELIVERY_SALE = 'basket/SET_DELIVERY_SALE',
  CHECK_ACTIONS_REQUEST = 'basket/CHECK_ACTIONS_REQUEST',
  SET_IS_UPDATE_CHECK_ACTION = 'basket/SET_IS_UPDATE_CHECK_ACTION',
  SET_IS_ERROR_UPDATE_CHECK_ACTION = 'basket/SET_IS_ERROR_UPDATE_CHECK_ACTION',
  SET_PERSONAL_COUPON = 'basket/SET_COUPON',
  SET_IS_SUCCESS_PERSONAL_COUPON = 'basket/SET_IS_SUCCESS_PERSONAL_COUPON',
}

const INITIAL_STATE: BasketState = {
  selectedProducts: [],
  isOpenClearBasketModal: false,
  isOpenBasketModalTerminal: false,
  isOpenAddCouponModalTerminal: false,
  countProductInBasket: null,
  isOpenDeleteProductFromBasketModal: false,
  deleteProductIndex: null,
  editProductIndex: null,
  priceFull: 0,
  saleFull: 0,
  deliverySale: null,
  isLoadCheckAction: false,
  isUpdateCheckActions: false,
  isErrorUpdateCheckActions: false,
  coupon: '',
  isSuccessPersonalCoupon: undefined,
}

export const Actions = {
  addProductWithOptionsInBasketRequest: (
    productItemState: ProductItemState,
    setDefaultIndex?: () => void,
  ): BaseAction => ({
    type: BasketActionsTypes.ADD_PRODUCT_WITH_OPTIONS_IN_BASKET_REQUEST,
    payload: {productItemState},
  }),

  changeCountSelectedProductInBasketRequest: (
    productIndex: number,
    count: number,
    isCheckActions?: boolean,
  ): BaseAction => ({
    type: BasketActionsTypes.CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_REQUEST,
    payload: {productIndex, count, isCheckActions},
  }),

  changeCountSelectedProductBasketSuccess: (
    selectedProducts: SelectedProductDTO[],
  ): BaseAction => ({
    type: BasketActionsTypes.CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_SUCCESS,
    payload: selectedProducts,
  }),

  toggleClearBasketModal: (isOpen: boolean): BaseAction => ({
    type: BasketActionsTypes.TOGGLE_CLEAR_BASKET_MODAL,
    payload: isOpen,
  }),

  toggleOpenBasketModalTerminal: (isOpen: boolean): BaseAction => ({
    type: BasketActionsTypes.TOGGLE_OPEN_BASKET_MODAL_TERMINAL,
    payload: isOpen,
  }),

  toggleOpenAddCouponModalTerminal: (isOpen: boolean): BaseAction => ({
    type: BasketActionsTypes.TOGGLE_OPEN_ADD_COUPON_MODAL_TERMINAL,
    payload: isOpen,
  }),

  clearBasketRequest: (): BaseAction => ({
    type: BasketActionsTypes.CLEAR_BASKET_REQUEST,
  }),

  clearBasketSuccess: (): BaseAction => ({
    type: BasketActionsTypes.CLEAR_BASKET_SUCCESS,
  }),

  addProductWithoutOptionsInBasketRequest: (selectedProduct: SelectedProductDTO): BaseAction => ({
    type: BasketActionsTypes.ADD_PRODUCT_WITHOUT_OPTIONS_IN_BASKET_REQUEST,
    payload: selectedProduct,
  }),

  addProductWithOneOptionInBasketRequest: (
    product: ProductDTO,
    optionCategoryId: string,
    optionId: string,
  ): BaseAction => ({
    type: BasketActionsTypes.ADD_PRODUCT_WITH_ONE_OPTION_IN_BASKET_REQUEST,
    payload: {product, optionCategoryId, optionId},
  }),

  setSelectedProducts: (selectedProducts: SelectedProductDTO[]): BaseAction => ({
    type: BasketActionsTypes.SET_SELECTED_PRODUCTS,
    payload: selectedProducts,
  }),

  setCountProductInBasket: (countProductInBasket: number): BaseAction => ({
    type: BasketActionsTypes.SET_COUNT_PRODUCT_IN_BASKET,
    payload: countProductInBasket,
  }),

  deleteProductFromBasketRequest: (index: number, isCheckActions?: boolean): BaseAction => ({
    type: BasketActionsTypes.DELETE_PRODUCT_FROM_BASKET_REQUEST,
    payload: {index, isCheckActions},
  }),

  toggleDeleteProductFromBasketModal: (
    isOpen: boolean,
    deleteProductIndex: number,
  ): BaseAction => ({
    type: BasketActionsTypes.TOGGLE_DELETE_PRODUCT_FROM_BASKET_MODAL,
    payload: {isOpen, deleteProductIndex},
  }),

  openProductScreenForEditRequest: (
    selectedProduct: SelectedProductDTO,
    editProductIndex: number,
  ): BaseAction => ({
    type: BasketActionsTypes.OPEN_PRODUCT_SCREEN_FOR_EDIT_REQUEST,
    payload: {selectedProduct, editProductIndex},
  }),

  setEditProductIndex: (editProductIndex: number): BaseAction => ({
    type: BasketActionsTypes.SET_EDIT_PRODUCT_INDEX,
    payload: editProductIndex,
  }),

  setPriceFull: (priceFull: number): BaseAction => ({
    type: BasketActionsTypes.SET_PRICE_FULL,
    payload: priceFull,
  }),

  setSaleFull: (saleFull: number): BaseAction => ({
    type: BasketActionsTypes.SET_SALE_FULL,
    payload: saleFull,
  }),

  setDeliverySale: (deliverySale: number): BaseAction => ({
    type: BasketActionsTypes.SET_DELIVERY_SALE,
    payload: deliverySale,
  }),

  addComboInBasketRequest: (selectedProduct: SelectedProductDTO): BaseAction => ({
    type: BasketActionsTypes.ADD_COMBO_IN_BASKET_REQUEST,
    payload: selectedProduct,
  }),

  checkActions: (type: 'load' | 'update', isByRestaurant: boolean): BaseAction => ({
    type: BasketActionsTypes.CHECK_ACTIONS_REQUEST,
    payload: {type, isByRestaurant},
  }),

  setIsUpdateCheckAction: (isUpdate: boolean): BaseAction => ({
    type: BasketActionsTypes.SET_IS_UPDATE_CHECK_ACTION,
    payload: isUpdate,
  }),

  setIsErrorUpdateCheckAction: (isUpdate: boolean): BaseAction => ({
    type: BasketActionsTypes.SET_IS_ERROR_UPDATE_CHECK_ACTION,
    payload: isUpdate,
  }),

  setCoupon: (coupon: string): BaseAction => ({
    type: BasketActionsTypes.SET_PERSONAL_COUPON,
    payload: coupon,
  }),

  setIsSuccessPersonalCoupon: (isSuccess: boolean) => ({
    type: BasketActionsTypes.SET_IS_SUCCESS_PERSONAL_COUPON,
    payload: isSuccess,
  }),
}

export function bindBasketActions(dispatch: any) {
  return bindActionCreators(Actions, dispatch)
}

export type BasketActionsType = typeof Actions

export default function basket(
  state: BasketState = INITIAL_STATE,
  action: BaseAction,
): BasketState {
  switch (action.type) {
    case BasketActionsTypes.CHANGE_COUNT_SELECTED_PRODUCT_IN_BASKET_SUCCESS: {
      return {
        ...state,
        selectedProducts: [...action.payload],
      }
    }

    case BasketActionsTypes.TOGGLE_CLEAR_BASKET_MODAL: {
      return {
        ...state,
        isOpenClearBasketModal: action.payload,
      }
    }

    case BasketActionsTypes.TOGGLE_OPEN_BASKET_MODAL_TERMINAL: {
      return {
        ...state,
        isOpenBasketModalTerminal: action.payload,
      }
    }

    case BasketActionsTypes.TOGGLE_OPEN_ADD_COUPON_MODAL_TERMINAL: {
      return {
        ...state,
        isOpenAddCouponModalTerminal: action.payload,
      }
    }

    case BasketActionsTypes.CLEAR_BASKET_SUCCESS: {
      return {
        ...state,
        isOpenClearBasketModal: false,
        selectedProducts: [],
        countProductInBasket: 0,
      }
    }

    case BasketActionsTypes.SET_SELECTED_PRODUCTS: {
      return {
        ...state,
        selectedProducts: action.payload,
        countProductInBasket: action.payload.length,
      }
    }

    case BasketActionsTypes.SET_COUNT_PRODUCT_IN_BASKET: {
      return {
        ...state,
        countProductInBasket: action.payload,
      }
    }

    case BasketActionsTypes.TOGGLE_DELETE_PRODUCT_FROM_BASKET_MODAL: {
      return {
        ...state,
        deleteProductIndex: action.payload.deleteProductIndex,
        isOpenDeleteProductFromBasketModal: action.payload.isOpen,
      }
    }

    case BasketActionsTypes.SET_EDIT_PRODUCT_INDEX: {
      return {
        ...state,
        editProductIndex: action.payload,
      }
    }

    case BasketActionsTypes.SET_PRICE_FULL: {
      return {
        ...state,
        priceFull: action.payload,
      }
    }
    case BasketActionsTypes.SET_SALE_FULL: {
      return {
        ...state,
        saleFull: action.payload,
      }
    }
    case BasketActionsTypes.SET_DELIVERY_SALE: {
      return {
        ...state,
        deliverySale: action.payload,
      }
    }

    case BasketActionsTypes.SET_IS_UPDATE_CHECK_ACTION: {
      return {
        ...state,
        isUpdateCheckActions: action.payload,
      }
    }

    case BasketActionsTypes.SET_IS_ERROR_UPDATE_CHECK_ACTION: {
      return {
        ...state,
        isErrorUpdateCheckActions: action.payload,
      }
    }

    case BasketActionsTypes.SET_PERSONAL_COUPON: {
      return {
        ...state,
        coupon: action.payload,
      }
    }

    case BasketActionsTypes.SET_IS_SUCCESS_PERSONAL_COUPON: {
      return {
        ...state,
        isSuccessPersonalCoupon: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}
