import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'
import {SiteSliderDTO, TerminalSliderDTO} from 'grilnica-store-share'

const loadSiteSliderListByCityIdService = async (
  cityId: string,
  errorActions: any,
): Promise<SiteSliderDTO[]> => {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/site/slider/find-by-city/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    errorActions.setError(error)
  }
}

const loadTerminalSliderListByCityIdService = async (
  cityId: string,
  errorActions: any,
): Promise<TerminalSliderDTO[]> => {
  try {
    return await apiGet(CONSTANTS.apiStoreUrl + '/site/slider-terminal/find-by-city/' + cityId, {
      cityId: cityId,
    })
  } catch (error) {
    errorActions.setError(error)
  }
}

export {loadSiteSliderListByCityIdService, loadTerminalSliderListByCityIdService}
