import {apiGet} from '../../api'
import {CONSTANTS} from '../../../constants'

async function loadSearchPreset(cityId: string, errorActions?: any): Promise<string[]> {
  try {
    const url: any = new URL(CONSTANTS.apiStoreUrl + '/search-preset')
    return await apiGet(url, {
      cityId: cityId,
    })
  } catch (error) {
    if (errorActions) {
      errorActions.setError(error)
    } else {
      console.error('loadSearchPreset error', error)
      throw error
    }
  }
}

export {loadSearchPreset}
