import React, {ReactNode, useMemo} from 'react'
import {LabelDTO, WeightMapDto} from 'grilnica-store-share'

import {LazyImage} from '../../../components/lazyImage/LazyImage'
import {CONSTANTS} from '../../../../../constants'
import {PromoItems} from '../../../site/containers/menu/components/product/productList/productItem/components/card/PromoItems'
import {WeightBlock} from '../../../components/product/WeightBlock'
import {getPricesAndWeight} from '../../../site/containers/menu/components/product/productList/productItem/utils/getPricesAndWeight'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {SalePriceTerminal} from './price/SalePriceTerminal'
import {PriceTerminal} from './price/PriceTerminal'
import {Button} from 'grilnica-reactstrap'

interface ProductCardBodyProps {
  product: ProductDTO
  imageFileName: string
  name: string
  weight: string
  onClick: () => void
  labels: LabelDTO[]

  renderPromo?: ReactNode
  isSaleModal: boolean
  weightMap: WeightMapDto

  sendToBasket: () => void
}

const ProductCardBodyTerminal: React.FC<ProductCardBodyProps> = ({
  product,
  imageFileName,
  name,
  onClick,
  labels,
  renderPromo,
  isSaleModal,
  weightMap,
  sendToBasket,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const {price, salePrice, minPrice, weight} = getPricesAndWeight(product, weightMap)

    const renderSendToBasketAndPrice = () => {
      let css: string
      let priceContent: React.ReactElement
      if (!salePrice) {
        css = 'w-100 d-flex align-items-center justify-content-between mt-x6'
        priceContent = <PriceTerminal minPrice={minPrice} price={price} />
      } else {
        css =
          'w-100 d-flex align-items-center justify-content-between mt-x6 sale-price-terminal-wrapper'
        priceContent = <SalePriceTerminal salePrice={salePrice} />
      }

      return (
        <div className={css}>
          {priceContent}
          <Button
            color={'primary'}
            size={'md'}
            isRoundedButton
            onClick={(e) => {
              e.stopPropagation()
              sendToBasket()
            }}>
            в корзину
          </Button>
        </div>
      )
    }

    return (
      <div
        className={'product-list-item-terminal d-flex flex-column cursor-pointer'}
        onClick={onClick}>
        <div className={'image-container mb-x1 ' + (isSaleModal ? 'is-sale-modal' : '')}>
          <LazyImage
            src={CONSTANTS.cdnUrl + '/' + imageFileName}
            typePlaceholder={'product'}
            alt={name}
            title={name}
          />
          <PromoItems labels={labels} />
          {renderPromo}
        </div>
        <div className={'d-flex flex-column align-items-start flex-grow-1 px-x8 pb-x6'}>
          <div className={'flex-grow-1'}>
            <h3 className={'product-name'}>{name}</h3>
            <WeightBlock weight={weight} className={'weight-terminal text-gray-600 mb-0 mt-x2'} />
          </div>
          {renderSendToBasketAndPrice()}
        </div>
      </div>
    )
  }, [
    product,
    weightMap,
    onClick,
    isSaleModal,
    imageFileName,
    name,
    labels,
    renderPromo,
    sendToBasket,
  ])

  return <>{renderContent}</>
}

export {ProductCardBodyTerminal}
