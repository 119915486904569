import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {connectComponent} from '../../../../../store/common'
import {State} from '../../../../../store/ducks'
import {bindActionActions} from '../../../../../store/action/duck'
import {ActionDefinition} from '../../../../../store/action/types/ActionDefinition'
import {BasketPromoBlock} from './BasketPromoBlock'
import {BasketActionsType, bindBasketActions} from '../../../../../store/ducks/basket'

interface ActionBlockProps {
  basketActions: BasketActionsType
  definitions: ActionDefinition[]
  selectedCouponDefinitionId: string
  actionActions: any
  isSuccessPersonalCoupon: boolean
  isUpdateCheckActions: boolean
  isOpenCouponModal: boolean
  coupon: string
  isTerminal: boolean
}

const ActionBlock: React.FC<ActionBlockProps> = ({
  basketActions,
  coupon,
  isUpdateCheckActions,
  selectedCouponDefinitionId,
  definitions,
  actionActions,
  isSuccessPersonalCoupon,
  isTerminal,
}): React.ReactElement => {
  const [localCoupon, setLocalCoupon] = useState<string>(coupon)
  const [isOpenInputCoupon, setIsOpenInputCoupon] = useState<boolean>(false)

  const onChangeSelectedDefinition = useCallback(
    (id: string) => {
      if (id === selectedCouponDefinitionId) {
        actionActions.setSelectedCouponDefinitionIdRequest(undefined)
      } else {
        actionActions.setSelectedCouponDefinitionIdRequest(id)
      }
    },
    [actionActions, selectedCouponDefinitionId],
  )

  const onCloseInputCoupon = useCallback(() => {
    setIsOpenInputCoupon(false)
  }, [])

  const onOpenInputCoupon = useCallback(() => {
    setIsOpenInputCoupon(true)
  }, [])

  const openAddCouponModalTerminal = useCallback(() => {
    basketActions.toggleOpenAddCouponModalTerminal(true)
  }, [basketActions])

  const checkActions = useCallback(() => {
    basketActions.checkActions('update', isTerminal)
  }, [basketActions, isTerminal])

  const onChangeCoupon = useCallback(() => {
    basketActions.setCoupon(localCoupon)
    onCloseInputCoupon()
  }, [basketActions, localCoupon, onCloseInputCoupon])

  const onChangeLocalCoupon = useCallback((value: string) => {
    setLocalCoupon(value)
  }, [])

  const removeCoupon = useCallback(() => {
    onChangeLocalCoupon('')
    basketActions.setCoupon('')
    onCloseInputCoupon()
  }, [basketActions, onChangeLocalCoupon, onCloseInputCoupon])

  useEffect(() => {
    checkActions()
  }, [checkActions])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <BasketPromoBlock
        localCoupon={localCoupon}
        onChangeLocalCoupon={onChangeLocalCoupon}
        isOpenInputCoupon={isOpenInputCoupon}
        onOpenInputCoupon={onOpenInputCoupon}
        coupon={coupon}
        onChangeCoupon={onChangeCoupon}
        onChangeSelectedDefinition={onChangeSelectedDefinition}
        definitions={definitions}
        isUpdateCheckActions={isUpdateCheckActions}
        selectedCouponDefinitionId={selectedCouponDefinitionId}
        isSuccessPersonalCoupon={isSuccessPersonalCoupon}
        removeCoupon={removeCoupon}
        isTerminal={isTerminal}
        openAddCouponModalTerminal={openAddCouponModalTerminal}
      />
    )
  }, [
    coupon,
    definitions,
    isOpenInputCoupon,
    isSuccessPersonalCoupon,
    isUpdateCheckActions,
    localCoupon,
    onChangeCoupon,
    onChangeLocalCoupon,
    onChangeSelectedDefinition,
    onOpenInputCoupon,
    removeCoupon,
    selectedCouponDefinitionId,
    isTerminal,
    openAddCouponModalTerminal,
  ])
  return <>{renderContent}</>
}

export default connectComponent(
  (state: State) => ({
    coupon: state.basket.coupon,
    isUpdateCheckActions: state.basket.isUpdateCheckActions,
    selectedCouponDefinitionId: state.action.selectedCouponDefinitionId,
    definitions: state.action.availableDefinitions,
    isSuccessPersonalCoupon: state.basket.isSuccessPersonalCoupon,
    isTerminal: state.terminal?.terminalAlias?.length > 0,
  }),
  (dispatch) => ({
    basketActions: bindBasketActions(dispatch),
    actionActions: bindActionActions(dispatch),
  }),
  ActionBlock,
)
