import React, {useMemo} from 'react'
import {RecommendationsProducts} from './RecommendationsProducts'
import {ProductDTO, RecommendationProductMap, WeightMapDto} from 'grilnica-store-share'
import {RecommendationsProductsTerminal} from '../../../../../terminal/containers/basket/recommendation/RecommendationsProductsTerminal'

interface RecommendationsBlockComponentProps {
  recommendationProductMap: RecommendationProductMap
  addRecommendationProductInBasket: (product: ProductDTO) => void
  weightMap: WeightMapDto
  isTerminal: boolean
}

const RecommendationsBlockComponent: React.FC<RecommendationsBlockComponentProps> = ({
  recommendationProductMap,
  addRecommendationProductInBasket,
  weightMap,
  isTerminal,
}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    let items: React.ReactElement[] = []
    for (let groupId in recommendationProductMap) {
      if (recommendationProductMap[groupId]) {
        const {products, catalogRecommendationsGroup} = recommendationProductMap[groupId]
        if (products?.length > 0) {
          items.push(
            isTerminal ? (
              <RecommendationsProductsTerminal
                key={catalogRecommendationsGroup.catalogRecommendationGroupId}
                id={catalogRecommendationsGroup.catalogRecommendationGroupId}
                products={products}
                catalogRecommendationsGroup={catalogRecommendationsGroup}
                addRecommendationProductInBasket={addRecommendationProductInBasket}
                weightMap={weightMap}
              />
            ) : (
              <RecommendationsProducts
                key={catalogRecommendationsGroup.catalogRecommendationGroupId}
                id={catalogRecommendationsGroup.catalogRecommendationGroupId}
                products={products}
                catalogRecommendationsGroup={catalogRecommendationsGroup}
                addRecommendationProductInBasket={addRecommendationProductInBasket}
                weightMap={weightMap}
              />
            ),
          )
        }
      }
    }
    return <>{items}</>
  }, [recommendationProductMap, addRecommendationProductInBasket, weightMap, isTerminal])

  return <>{renderContent}</>
}

export {RecommendationsBlockComponent}
