import React, {useMemo} from 'react'
import {Col} from 'grilnica-reactstrap'
import classNames from 'classnames'

interface BasketAlertBlockProps {
  isTerminal: boolean
}

const BasketAlertBlock: React.FC<BasketAlertBlockProps> = ({isTerminal}): React.ReactElement => {
  const renderContent: React.ReactElement = useMemo(() => {
    const content = (
      <div className={classNames('alert-basket text-center mb-x8', {terminal: isTerminal})}>
        <p className={'alert-name mb-x2'}>Обратите внимание!</p>
        <p className={'alert-description mb-0'}>Скидки по акциям не суммируются.</p>
      </div>
    )
    if (isTerminal) {
      return content
    } else {
      return <Col md={{size: 6, offset: 3}}>{content}</Col>
    }
  }, [isTerminal])
  return <React.Fragment>{renderContent}</React.Fragment>
}

export {BasketAlertBlock}
