import React from 'react'
import {getPriceName} from '../../../../site/containers/menu/components/product/productList/productItem/utils/getPricesAndWeight'

interface PriceTerminalProps {
  minPrice: number | undefined
  price: number
  className?: string
}

const PriceTerminal: React.FC<PriceTerminalProps> = ({minPrice, price, className}) => {
  return (
    <span className={'price-terminal'}>{getPriceName(null, minPrice, price, false)} &#8381;</span>
  )
}

export {PriceTerminal}
