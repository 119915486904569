import {call, put, select} from 'redux-saga/effects'
import {State} from '../../ducks'
import {LSKeys, LSMethods} from '../../../storage'
import {Actions as ErrorActions} from '../../ducks/error'
import {CityDTO} from 'grilnica-share'
import {loadSearchPreset} from '../../../services/search'

function* initializeSearch() {
  console.log('*initializeSearch')
  try {
    yield call(getSearchValues)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

function* getSearchValues() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    const searchValues: string[] = yield call(loadSearchPreset, city?.cityId)
    yield call(LSMethods.setStorageData, LSKeys.SEARCH_VALUES, searchValues)
  } catch (error) {
    yield put(ErrorActions.setError(error))
  }
}

export {initializeSearch}
