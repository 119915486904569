import {MenuCategoryDTO, ProductDTO} from 'grilnica-store-share'
import {ProductMenuInfoDto} from '../../../terminal/containers/product/ProductMenuInfoDto'

function getProductMenuInfo(
  categoryList: MenuCategoryDTO[],
  product: ProductDTO,
): ProductMenuInfoDto {
  let categoryAlias: string = null
  let childCategoryAlias: string = null
  for (let category of categoryList) {
    if (category.menuCategoryId === product.menuCategoryId) {
      categoryAlias = category.alias
      if (category.parentMenuCategoryId) {
        for (let item of categoryList) {
          if (category.parentMenuCategoryId === item.menuCategoryId) {
            childCategoryAlias = categoryAlias
            categoryAlias = item.alias
            break
          }
        }
      }
      break
    }
  }
  return {categoryAlias, childCategoryAlias, productAlias: product.alias}
}

const getUrl = (product: ProductDTO, categoryList: MenuCategoryDTO[]): string => {
  let {categoryAlias, childCategoryAlias} = getProductMenuInfo(categoryList, product)

  return '/menu/' + categoryAlias + (childCategoryAlias ? '/' + childCategoryAlias : '')
}

export {getUrl, getProductMenuInfo}
