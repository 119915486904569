import React, {useCallback, useMemo} from 'react'
import {ProductDTO, CatalogRecommendationGroupDTO, WeightMapDto} from 'grilnica-store-share'
import {Button} from 'grilnica-reactstrap'
import {isEmpty} from 'grilnica-share'
import classNames from 'classnames'
import {
  getPriceName,
  getPricesAndWeight,
} from '../../../../site/containers/menu/components/product/productList/productItem/utils/getPricesAndWeight'
import {LazyImage} from '../../../../components/lazyImage/LazyImage'
import {CONSTANTS} from '../../../../../../constants'
import {basketSVG} from '../../../../components/svg/SystemIcons'
import {ScrollableContainerTerminal} from './ScrollableContainerTerminal'

const CARD_SIZE: number = 344

interface RecommendationsProductsProps {
  id: string
  products: ProductDTO[]
  catalogRecommendationsGroup: CatalogRecommendationGroupDTO
  addRecommendationProductInBasket: (product: ProductDTO) => void
  weightMap: WeightMapDto
}

const RecommendationsProductsTerminal: React.FC<RecommendationsProductsProps> = ({
  id,
  products,
  catalogRecommendationsGroup,
  addRecommendationProductInBasket,
  weightMap,
}): React.ReactElement => {
  const getRenderProductCard = useCallback(
    (product: ProductDTO, index: number) => {
      const {price, salePrice, minPrice, weight} = getPricesAndWeight(product, weightMap)
      return (
        <div
          key={catalogRecommendationsGroup.catalogRecommendationGroupId + '-' + index}
          id={catalogRecommendationsGroup.catalogRecommendationGroupId + '-' + index}
          className={'pl-x2 pr-x2'}>
          <div
            className={'rp-card'}
            onClick={() => {
              addRecommendationProductInBasket(product)
            }}>
            <div className={'rp-img'}>
              <LazyImage
                src={CONSTANTS.cdnUrl + '/' + product.imageFileName}
                typePlaceholder={'product'}
                alt={product.name}
                title={product.name}
              />
            </div>
            <div
              className={classNames('d-flex flex-column justify-content-between pl-x1 w-100 p-x4')}>
              <p className={'rp-name mb-x2'}>{product.name}</p>
              <div className={'d-flex flex-row justify-content-between align-items-center w-100'}>
                <span className={'weight caption-2 text-gray-700 mr-x1'}>{weight}</span>
                <Button
                  onClick={() => {}}
                  size={'sm'}
                  iconPath={basketSVG}
                  iconPosition={'left'}
                  isRoundedButton
                  color={isEmpty(salePrice) ? 'primary' : 'sale'}>
                  {getPriceName(salePrice, minPrice, price, true)} &#8381;
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    },
    [
      catalogRecommendationsGroup?.catalogRecommendationGroupId,
      addRecommendationProductInBasket,
      weightMap,
    ],
  )

  const renderProducts: React.ReactElement = useMemo(() => {
    let items: React.ReactElement[] = products.map((product, index) =>
      getRenderProductCard(product, index),
    )
    return <>{items}</>
  }, [getRenderProductCard, products])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <div className={classNames('recommendations-product-list-container-terminal')}>
        <p className={'caption-select-promo mt-x8 mb-x2'}>{catalogRecommendationsGroup.name}</p>
        <ScrollableContainerTerminal
          id={id}
          itemSize={CARD_SIZE}
          countItems={products?.length}
          classNameBody={'recommendations-product-list-terminal'}>
          {renderProducts}
        </ScrollableContainerTerminal>
      </div>
    )
  }, [catalogRecommendationsGroup?.name, id, products?.length, renderProducts])

  return <>{renderContent}</>
}

export {RecommendationsProductsTerminal}
