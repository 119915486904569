import {
  ProductDTO,
  ProductMap,
  SelectedComboProductMap,
  SelectedOption,
  SelectedProductDTO,
} from 'grilnica-store-share'
import PrepareProductEntity from '../../product/PrepareProductEntity'
import {ProductVariantOptionDTO} from 'grilnica-store-share/lib/product/ProductVariantOptionDTO'
import {SelectedOptionsMap} from '../../../../../../../../../../types/product/SelectedOptionsMap'
import {OptionMap} from '../../../../../../../../../../types/product/OptionMap'
import {OptionCategoryMap} from '../../../../../../../../../../types/product/OptionCategoryMap'
import {RestrictionsMap} from '../../../../../../../../../../types/product/RestrictionsMap'
import {getSelectedProduct} from './getSelectedProduct'
import {ProductItemActionsTypes} from '../../ducks/ProductItemDuck'

const selectComboService = async (
  combo: ProductDTO,
  productMap: ProductMap,
  dispatch: any,
  editCombo?: SelectedProductDTO,
) => {
  const selectedProductsMap: SelectedComboProductMap = {}

  let allOptionPrice: number = 0

  for (let comboGroup of combo.comboGroupList ?? []) {
    let selectedComboProduct: SelectedProductDTO
    if (editCombo) {
      selectedComboProduct = editCombo.selectedProducts[comboGroup.comboGroupId][0]
    }
    const comboSelectedProducts: SelectedProductDTO[] = []

    for (let comboProduct of comboGroup.productList ?? []) {
      let product: ProductDTO
      product = productMap[comboProduct.menuCategoryId].find(
        (item: ProductDTO) => item.productId === comboProduct.productId,
      )

      const prepareProduct = new PrepareProductEntity(product)

      let isProductVariant: boolean = false
      if (product.productVariants && product.productVariants.length !== 0) {
        prepareProduct.createProductVariantMap()
        isProductVariant = true
      }

      if (product.optionCategories) {
        for (let optionCategory of product.optionCategories) {
          prepareProduct.sortOptionCategoryByType(optionCategory)
          //случай если комбо редактируется из корзины
          if (
            selectedComboProduct &&
            selectedComboProduct.product.productId === product.productId &&
            (selectedComboProduct.productVariantId
              ? selectedComboProduct.productVariantId === comboProduct.objectId
              : true) &&
            selectedComboProduct.selectedOptions.length !== 0
          ) {
            let currentSelectedOption: SelectedOption = selectedComboProduct.selectedOptions.find(
              (selectedOption) =>
                selectedOption.optionCategoryId === optionCategory.optionCategoryId,
            )
            if (currentSelectedOption) {
              for (let optionId of currentSelectedOption.optionIds) {
                prepareProduct.addSelectedOptionsInMaps(optionCategory, optionId, true)
              }
            } else {
              prepareProduct.addSelectedOptionsInMaps(optionCategory, null, true)
            }
          } else if (comboProduct.productVariant) {
            //слуйчай ообычного открытия комбо
            const productVariant: ProductVariantOptionDTO = comboProduct.productVariant.find(
              (item) => item.optionCategoryId === optionCategory.optionCategoryId,
            )
            if (productVariant) {
              prepareProduct.addSelectedOptionsInMaps(optionCategory, productVariant.optionId)
            } else {
              prepareProduct.addSelectedOptionsInMaps(optionCategory)
            }
          } else {
            prepareProduct.addSelectedOptionsInMaps(optionCategory)
          }

          prepareProduct.addOptionCategoryInMap(optionCategory)
          prepareProduct.createOptionMap(optionCategory)
        }
      }
      prepareProduct.addSelectedProductPriceAndProductVariant()
      allOptionPrice = allOptionPrice + prepareProduct.getSumPriceOptions()

      const selectedOptionsMap: SelectedOptionsMap = prepareProduct.getSelectedOptionsMap()
      const optionMap: OptionMap = prepareProduct.getOptionMap()
      const optionCategoryMap: OptionCategoryMap = prepareProduct.getOptionCategoryMap()
      const restrictionsMap: RestrictionsMap = prepareProduct.getRestrictionsMap()
      const newSelectedProduct: SelectedProductDTO = getSelectedProduct(
        {...prepareProduct.getSelectedProduct()},
        selectedOptionsMap,
        optionMap,
        optionCategoryMap,
        restrictionsMap,
      )

      let isActive = false
      if (isProductVariant) {
        isActive = selectedComboProduct
          ? selectedComboProduct.product.productId === product.productId &&
            selectedComboProduct.productVariantId === comboProduct.objectId
            ? selectedComboProduct.isActive
            : false
          : comboProduct.isDefault
      } else {
        isActive = selectedComboProduct
          ? selectedComboProduct.product.productId === product.productId
            ? selectedComboProduct.isActive
            : false
          : comboProduct.isDefault
      }

      comboSelectedProducts.push({
        ...newSelectedProduct,
        isActive: isActive,
        iikoComboGroupId: comboGroup.groupId,
      })
    }
    selectedProductsMap[comboGroup.comboGroupId] = comboSelectedProducts
  }

  const selectedCombo: SelectedProductDTO = {
    product: combo,
    productId: combo.productId,
    count: editCombo ? editCombo.count : 1,
    price: combo.price + allOptionPrice,
    selectedProducts: selectedProductsMap,
    selectedProductId: combo.productId,
    maxCountInOrder: combo.maxCountInOrder,
  }

  dispatch({
    type: ProductItemActionsTypes.SET_SELECTED_PRODUCT,
    payload: selectedCombo,
  })
}

export {selectComboService}
