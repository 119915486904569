import React, {useCallback, useEffect, useMemo, useReducer} from 'react'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {
  MenuCategoryDTO,
  MenuEntityTypeEnum,
  ProductMap,
  RestaurantByProductMap,
  SelectedProductDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {
  initialProductItemState,
  ProductItemActions,
  ProductItemActionsTypes,
  ProductItemReducer,
  ProductItemState,
} from './ducks/ProductItemDuck'
import {bindErrorActions, ErrorActionsType} from '../../../../../../../../store/ducks/error'
import {toggleOptionService} from './services/product/toggleOptionService'
import {selectProductService} from './services/product/selectProductService'
import {BasketActionsType, bindBasketActions} from '../../../../../../../../store/ducks/basket'
import {ProductCard} from './components/ProductCard'
import {connectComponent} from '../../../../../../../../store/common'
import {selectComboService} from './services/combo/selectComboService'
import {State} from '../../../../../../../../store/ducks'
import {HappyHourAction} from '../../../../../../../../store/action/types/HappyHourActionMap'
import {SaleTimer} from '../../../../../../components/timer/SaleTimer'
import moment from 'moment'
import {bindProductActions, ProductActionsType} from '../../../../../../../../store/ducks/product'

export const ProductItemDispatch = React.createContext(null)

interface ProductItemProps {
  restaurantByProductMap?: RestaurantByProductMap
  restaurantId?: string
  errorActions?: ErrorActionsType
  basketActions: BasketActionsType
  productActions: ProductActionsType
  product?: ProductDTO
  url?: string
  colSize?: any
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  productItemMapByProduct: any
  isTerminal: boolean

  classNameProductCard: string
  isSaleModal: boolean
  happyHourDay: HappyHourAction
  happyHourWeek: HappyHourAction
  currentDayProps?: number
  weightMap: WeightMapDto
}

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  restaurantByProductMap,
  basketActions,
  productActions,
  url,
  colSize,
  productMap,
  menuCategoryList,
  productItemMapByProduct,
  isTerminal,
  classNameProductCard,
  isSaleModal,
  happyHourDay,
  happyHourWeek,
  currentDayProps,
  weightMap,
}): React.ReactElement => {
  const [productItemState, dispatch] = useReducer<
    React.Reducer<ProductItemState, ProductItemActions>
  >(
    ProductItemReducer,
    productItemMapByProduct &&
      Object.keys(productItemMapByProduct).length !== 0 &&
      productItemMapByProduct[product.productId]
      ? productItemMapByProduct[product.productId]
      : initialProductItemState,
  )

  const getRestaurantByProduct = useCallback(async () => {
    if (restaurantByProductMap) {
      dispatch({
        type: ProductItemActionsTypes.SET_RESTAURANT_BY_PRODUCT_MAP,
        payload: restaurantByProductMap,
      })
    }
  }, [restaurantByProductMap])

  const openProductInModal = useCallback(async () => {
    if (product) {
      productActions.setOpenProductInModal(product, null)
    }
  }, [product, productActions])

  const toggleOption = useCallback(
    (optionCategoryId: string, optionId: string) => {
      toggleOptionService(
        optionCategoryId,
        optionId,
        true,
        false,
        productItemState.supportsMaps,
        productItemState.selectedProduct,
        dispatch,
      )
    },
    [productItemState?.selectedProduct, productItemState?.supportsMaps],
  )

  const getProduct = useCallback(async () => {
    await selectProductService(product, dispatch)
  }, [product])

  const getComboProduct = useCallback(async () => {
    selectComboService(product, productMap, dispatch)
  }, [product, productMap])

  useEffect(() => {
    if (restaurantByProductMap) {
      getRestaurantByProduct()
    }
  }, [getRestaurantByProduct, restaurantByProductMap])

  useEffect(() => {
    if (product && productMap) {
      if (product.type === MenuEntityTypeEnum.COMBO) {
        getComboProduct()
      } else {
        getProduct()
      }
    }
  }, [getComboProduct, getProduct, product, productMap])

  const sendToBasket = useCallback(() => {
    if (
      productItemState.selectedProduct &&
      productItemState.selectedProduct.product.optionCategories
    ) {
      const newProductItemState: ProductItemState = JSON.parse(JSON.stringify(productItemState))
      basketActions.addProductWithOptionsInBasketRequest(newProductItemState)
    } else {
      const newSelectedProduct: SelectedProductDTO = JSON.parse(
        JSON.stringify(productItemState.selectedProduct),
      )
      basketActions.addProductWithoutOptionsInBasketRequest(newSelectedProduct)
    }
  }, [basketActions, productItemState])

  const isProductDay = useMemo(() => {
    return happyHourDay?.productId === product?.productId
  }, [happyHourDay?.productId, product?.productId])

  const isProductWeek = useMemo(() => {
    return happyHourWeek?.productId === product?.productId
  }, [happyHourWeek?.productId, product?.productId])

  const currentDay = currentDayProps ? currentDayProps : moment().day()

  const renderPromo = useMemo(() => {
    if ((isProductDay || isProductWeek) && !isTerminal) {
      return (
        <SaleTimer
          isProductWeek={isProductWeek}
          endDate={isProductWeek ? happyHourWeek?.endDate : happyHourDay?.endDate}
          className={'timer'}
          currentDay={currentDay}
        />
      )
    }
  }, [
    currentDay,
    happyHourDay?.endDate,
    happyHourWeek?.endDate,
    isProductDay,
    isProductWeek,
    isTerminal,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <ProductItemDispatch.Provider value={dispatch}>
        {productItemState.selectedProduct && (
          <ProductCard
            selectedProduct={productItemState.selectedProduct}
            supportsMaps={productItemState.supportsMaps}
            restaurantByProductMap={restaurantByProductMap}
            product={productItemState.selectedProduct.product}
            toggleOption={toggleOption}
            sendToBasket={sendToBasket}
            url={url}
            onClick={openProductInModal}
            colSize={colSize}
            isTerminal={isTerminal}
            productMap={productMap}
            menuCategoryList={menuCategoryList}
            className={classNameProductCard}
            isSaleModal={isSaleModal}
            isProductDay={isProductDay}
            isProductWeek={isProductWeek}
            renderPromo={renderPromo}
            weightMap={weightMap}
          />
        )}
      </ProductItemDispatch.Provider>
    )
  }, [
    productItemState.selectedProduct,
    productItemState.supportsMaps,
    restaurantByProductMap,
    toggleOption,
    sendToBasket,
    url,
    colSize,
    isTerminal,
    productMap,
    menuCategoryList,
    classNameProductCard,
    isSaleModal,
    isProductDay,
    isProductWeek,
    renderPromo,
    weightMap,
    openProductInModal,
  ])
  return <>{renderContent}</>
}

export default connectComponent(
  (state: State) => ({
    productItemMapByProduct: state.initialize.productItemMapByProduct,
    isTerminal: state.terminal?.terminalAlias?.length > 0,
    happyHourDay: state.common.happyHoursActions?.day
      ? state.common.happyHoursActions?.day[state.city?.selectedCity?.cityId]
      : null,
    happyHourWeek: state.common.happyHoursActions?.week
      ? state.common.happyHoursActions?.week[state.city?.selectedCity?.cityId]
      : null,
    weightMap: state.menu.weightMap,
  }),
  (dispatch: any) => ({
    errorActions: bindErrorActions(dispatch),
    productActions: bindProductActions(dispatch),
    basketActions: bindBasketActions(dispatch),
  }),
  ProductItem,
)
