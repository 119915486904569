import {ActionDTO} from 'grilnica-store-share'
import {State} from '../../ducks'
import {call, put, select} from 'redux-saga/effects'
import {Actions as ActionActions} from '../duck/actions'
import {SelectedProductDTO} from '../../../types/product/SelectedProductDTO'
import {OrderDTO} from 'grilnica-store-share/lib/order/OrderDTO'
import {createNewOrder} from '../../sagas/order/order'
import {
  getAvailableActionsByRestaurantService,
  getAvailableActionsService,
} from '../../../services/action'
import {SaleResponse} from '../types/SaleResponse'
import {checkActions} from '../../sagas/basket/basket'
import {Actions as ErrorActions} from '../../ducks/error'
import {SaveOrderEnum} from '../../../types/order/SaveOrderEnum'
import {TerminalState} from '../../terminal/types'

function* selectAction({payload}: any) {
  const actionId: string = payload.actionId
  const moveToActionScreen: () => void = payload.moveToActionScreen
  const actions: ActionDTO[] = yield select((state: State) => state.action.actions)
  const selectedAction: ActionDTO | undefined = actions.find((item) => item.actionId === actionId)
  if (selectedAction) {
    yield put(ActionActions.setSelectedAction(selectedAction))
    yield call(moveToActionScreen)
  }
}

function* getAvailableActionsByRestaurant(selectedCouponDefinitionId: string) {
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalAlias: string = terminalState?.terminalAlias
  const personalCoupon: string = yield select((state: State) => state.basket.coupon)
  try {
    const order: OrderDTO = yield call(
      createNewOrder,
      terminalAlias ? SaveOrderEnum.TERMINAL : SaveOrderEnum.NORMAL,
    )

    if (personalCoupon) {
      order.personalCoupon = personalCoupon
    }
    order.couponDefinitionId = selectedCouponDefinitionId

    const response: SaleResponse = yield call(getAvailableActionsByRestaurantService, order)

    yield put(ActionActions.setAvailableDefinitions(response.definitions))
    yield put(ActionActions.setSelectedCouponDefinitionId(response.selectedCouponDefinitionId))

    return response
  } catch (error) {
    console.log('Error getAvailableActionsByRestaurant')
    yield put(ErrorActions.setError(error))
    throw error
  }
}

function* getAvailableActions(
  selectedProducts: SelectedProductDTO[],
  cityId: string,
  selectedCouponDefinitionId: string,
  isTerminal: boolean,
) {
  const personalCoupon: string = yield select((state: State) => state.basket.coupon)

  try {
    const order: OrderDTO = yield call(
      createNewOrder,
      isTerminal ? SaveOrderEnum.TERMINAL : SaveOrderEnum.NORMAL,
    )

    if (personalCoupon) {
      order.personalCoupon = personalCoupon
    }
    order.couponDefinitionId = selectedCouponDefinitionId

    const response: SaleResponse = yield call(getAvailableActionsService, order)

    yield put(ActionActions.setAvailableDefinitions(response.definitions))
    yield put(ActionActions.setSelectedCouponDefinitionId(response.selectedCouponDefinitionId))

    return response
  } catch (error) {
    console.log('Error getAvailableActions')
    yield put(ErrorActions.setError(error))
    throw error
  }
}

function* changeSelectedActionCoupon({payload}: any) {
  yield call(checkActions, {
    payload: {type: 'load', newSelectedActionCouponId: payload ? payload : 'NONE_COUPON'},
  })
}

function* clearAvailableActions() {
  yield put(ActionActions.setAvailableDefinitions([]))
  yield put(ActionActions.setSelectedCouponDefinitionId(undefined))
}

export {
  selectAction,
  getAvailableActions,
  clearAvailableActions,
  changeSelectedActionCoupon,
  getAvailableActionsByRestaurant,
}
