import {Actions as MenuActions} from '../ducks/menu'
import {Actions as ErrorActions} from '../ducks/error'
import {put, call, select, delay, takeEvery} from 'redux-saga/effects'
import {State} from '../ducks'
import {LSKeys, LSMethods} from '../../storage'
import {CityDTO, isEmpty, isEmptyObject, TerminalConfigDTO} from 'grilnica-share'
import {MenuCategoryDTO, ProductMap, WeightMapDto} from 'grilnica-store-share'
import {loadCategoryListService, loadProductMapService} from '../../services'
import {CatalogItemsTypeEnum} from '../../types/menu/CatalogItemsTypeEnum'
import {Actions as InitializeActions} from '../ducks/initialize'
import {ErrorInitializeEnum} from '../../types/common/ErrorInitializeEnum'
import {loadProductMapTerminalService, loadWeightMapService} from '../../services/menu'
import {eventChannel} from 'redux-saga'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {TerminalState} from '../terminal/types'

function* initializeMenu() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (selectedCity) {
    try {
      yield call(loadCategoryList)
      yield call(loadProductMap) //TODO:: make
      yield call(loadWeightMap)
    } catch (error) {
      yield put(ErrorActions.setError(error))
    }
  } else {
    console.error('Not city initializeMenu')
    yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY))
  }
}

function* loadCategoryList() {
  try {
    const catalogItemsType: CatalogItemsTypeEnum = yield call(
      LSMethods.getStorageData,
      LSKeys.SELECTED_CATALOG_ITEMS_TYPE,
      CatalogItemsTypeEnum.BIG_ITEMS,
    )
    yield put(MenuActions.toggleCatalogItemsTypeSuccess(catalogItemsType))
    yield call(getCategoryList)
  } catch (error) {
    console.log('Error load category list', error)
    yield put(ErrorActions.setError(error))
    const categoryList: MenuCategoryDTO[] = yield call(
      LSMethods.getStorageData,
      LSKeys.CATEGORY_LIST,
      [],
    )
    yield put(MenuActions.loadCategoryListSuccess(categoryList))
  }
}

function* getCategoryList() {
  try {
    const city: CityDTO = yield call(LSMethods.getStorageData, LSKeys.SELECTED_CITY, null)
    if (city) {
      let categoryList: MenuCategoryDTO[] = yield call(loadCategoryListService, city.cityId)
      if (!categoryList || categoryList?.length === 0) {
        console.error('Empty categoryList', categoryList)
        categoryList = []
        yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_CATALOG_LIST))
      }
      yield call(setCategoryList, categoryList)
    }
  } catch (error) {
    throw error
  }
}

function* setCategoryList(categoryList: MenuCategoryDTO[]) {
  yield put(MenuActions.loadCategoryListSuccess(categoryList))
  yield call(LSMethods.setStorageData, LSKeys.CATEGORY_LIST, categoryList)
}

function* closeFastBuyModal({payload}: any) {
  const setDefaultIndex: () => void = payload
  try {
    yield put(MenuActions.toggleFastBuyModal(false))
    yield call(setDefaultIndex)
  } catch (error) {
    console.log(error)
  }
}

function* toggleCatalogItemsType({payload}: any) {
  try {
    yield put(MenuActions.setIsChangeCatalogItems(true))
    yield delay(1000)
    const catalogItemsType: CatalogItemsTypeEnum = payload
    yield put(MenuActions.toggleCatalogItemsTypeSuccess(catalogItemsType))
    yield call(LSMethods.setStorageData, LSKeys.SELECTED_CATALOG_ITEMS_TYPE, catalogItemsType)
    yield delay(1000)
    yield put(MenuActions.setIsChangeCatalogItems(false))
  } catch (error) {
    console.log(error)
  }
}

function* loadProductMap() {
  try {
    //TODO:: update to mobile
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    const categoryList: MenuCategoryDTO[] = yield select((state: State) => state.menu.categoryList)
    let productMap: ProductMap = yield call(loadProductMapService, city.cityId)
    if (isEmptyObject(productMap)) {
      console.error('Empty productMap', productMap)
      productMap = {}
      yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_PRODUCT_MAP))
    }
    let isUpdateCategories: boolean = false
    for (let category of categoryList) {
      if (category.isActionCategory) {
        const newP: ProductDTO[] = []
        for (let itemC of categoryList) {
          if (
            itemC.parentMenuCategoryId === category.parentMenuCategoryId ||
            itemC.menuCategoryId === category.parentMenuCategoryId
          ) {
            for (let itemP of productMap[itemC.menuCategoryId] || []) {
              if (!isEmpty(itemP.salePrice)) {
                newP.push(itemP)
              }
            }
          }
        }
        if (newP.length > 0) {
          productMap[category.menuCategoryId] = newP.sort((a, b) => {
            return b.salePrice - a.salePrice
          })
        } else {
          isUpdateCategories = true
          category.isActionCategory = false
        }
      }
    }
    if (isUpdateCategories) {
      yield call(setCategoryList, categoryList)
    }
    yield call(setProductMap, productMap)
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const productMap: ProductMap = yield call(LSMethods.getStorageData, LSKeys.PRODUCT_MAP, null)
    yield put(MenuActions.loadProductMapSuccess(productMap))
  }
}

function* setProductMap(productMap: ProductMap) {
  yield put(MenuActions.loadProductMapSuccess(productMap))
  yield call(LSMethods.setStorageData, LSKeys.PRODUCT_MAP, productMap)
}

function* loadWeightMap() {
  try {
    //смотрим на тот что загружен в сервер сайт рендоре
    const weightMapLISSR: WeightMapDto = yield select((state: State) => state.menu.weightMap)
    if (isEmptyObject(weightMapLISSR)) {
      const city: CityDTO = yield select((state: State) => state.city.selectedCity)
      let weightMap: WeightMapDto = yield call(loadWeightMapService, city.cityId)
      if (isEmptyObject(weightMap)) {
        console.error('Empty weightMap', weightMap)
        weightMap = {}
      }
      yield call(setWeightMap, weightMap)
    }
  } catch (error) {
    const weightMap: WeightMapDto = yield call(LSMethods.getStorageData, LSKeys.WEIGHT_MAP, null)
    yield put(MenuActions.loadWeightMapSuccess(weightMap))
  }
}

function* setWeightMap(weightMap: WeightMapDto) {
  yield put(MenuActions.loadWeightMapSuccess(weightMap))
  yield call(LSMethods.setStorageData, LSKeys.WEIGHT_MAP, weightMap)
}

function* initializeMenuTerminal() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  if (selectedCity) {
    try {
      yield call(loadCategoryList)
      yield call(loadProductMapTerminal)
      yield call(loadWeightMap)
    } catch (error) {
      yield put(ErrorActions.setError(error))
    }
  } else {
    console.error('Not city initializeMenuTerminal')
    yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_SELECTED_CITY))
  }
}

function countdown(secs, time) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      emitter(secs)
    }, time)
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv)
    }
  })
}

function* initializeBackgroundUpdateMenu() {
  let selectedCity: CityDTO = yield select((state: State) => state.city.selectedCity)
  const terminalState: TerminalState = yield select((state: State) => state.terminal)
  const terminalConfig: TerminalConfigDTO = terminalState?.terminalConfig
  const timeOfDataUpdate: number = terminalConfig?.timeOfDataUpdate
  console.log('timeOfDataUpdate', timeOfDataUpdate)

  if (selectedCity) {
    try {
      const channel = yield call(countdown, 0, timeOfDataUpdate)
      yield takeEvery(channel, function* () {
        // TODO: Не обновлять меню, если пользователь сейчас делает выбор
        console.log('!!!!!!!!!!!!! UPDATE_MENU !!!!!!!!!!!!!!!!!')
        yield call(loadCategoryList)
        yield call(loadProductMapTerminal)
      })
    } catch (error) {
      yield put(ErrorActions.setError(error))
    }
  }
}

function* loadProductMapTerminal() {
  try {
    const city: CityDTO = yield select((state: State) => state.city.selectedCity)
    let productMap: ProductMap = yield call(loadProductMapTerminalService, city?.cityId)
    const categoryList: MenuCategoryDTO[] = yield select((state: State) => state.menu.categoryList)
    if (isEmptyObject(productMap)) {
      console.error('Empty productMap', productMap)
      productMap = {}
      yield put(InitializeActions.setErrorInitialize(ErrorInitializeEnum.ERROR_LOAD_PRODUCT_MAP))
    }

    let isUpdateCategories: boolean = false
    for (let category of categoryList) {
      if (category.isActionCategory) {
        const newP: ProductDTO[] = []
        for (let itemC of categoryList) {
          if (
            itemC.parentMenuCategoryId === category.parentMenuCategoryId ||
            itemC.menuCategoryId === category.parentMenuCategoryId
          ) {
            for (let itemP of productMap[itemC.menuCategoryId] || []) {
              if (!isEmpty(itemP.salePrice)) {
                newP.push(itemP)
              }
            }
          }
        }
        if (newP.length > 0) {
          productMap[category.menuCategoryId] = newP.sort((a, b) => {
            return b.salePrice - a.salePrice
          })
        } else {
          isUpdateCategories = true
          category.isActionCategory = false
        }
      }
    }
    if (isUpdateCategories) {
      yield call(setCategoryList, categoryList)
    }
    yield call(setProductMap, productMap)
  } catch (error) {
    yield put(ErrorActions.setError(error))
    const productMap: ProductMap = yield call(LSMethods.getStorageData, LSKeys.PRODUCT_MAP, null)
    yield put(MenuActions.loadProductMapSuccess(productMap))
  }
}

export {
  initializeMenu,
  initializeMenuTerminal,
  loadCategoryList,
  closeFastBuyModal,
  toggleCatalogItemsType,
  initializeBackgroundUpdateMenu,
}
