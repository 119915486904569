import React from 'react'
import classNames from 'classnames'

interface SalePriceTerminalProps {
  salePrice: number
  className?: string
}

const SalePriceTerminal: React.FC<SalePriceTerminalProps> = ({salePrice, className}) => {
  return <span className={classNames('sale-price-terminal', className)}>{salePrice} &#8381;</span>
}

export {SalePriceTerminal}
