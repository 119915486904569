import React, {ReactNode, useMemo, useState} from 'react'
import {Col} from 'grilnica-reactstrap'
import {CardModal} from '../../../../../../../../components/modal/CardModal'
import {
  MenuCategoryDTO,
  MenuEntityTypeEnum,
  ProductMap,
  ProductVariantDTO,
  RestaurantByProductMap,
  SelectedProductDTO,
  WeightMapDto,
} from 'grilnica-store-share'
import {ProductOptions} from './ProductOptions'
import {ProductCardFooter} from './card/ProductCardFooter'
import {ProductCardBody} from './card/ProductCardBody'
import {ProductDTO} from 'grilnica-store-share/lib/product/ProductDTO'
import {SupportsMaps} from '../../../../../../../../../types/product/SupportsMaps'
import {ModalProductRestaurantContent} from './card/ModalProductRestaurantContent'
import {getWeightWithOptions} from '../../../../../../../../../utils/menu/getWeight'
import {ProductCardBodyTerminal} from '../../../../../../../../terminal/containers/product/ProductCardBodyTerminal'

interface ProductCardProps {
  selectedProduct: SelectedProductDTO
  supportsMaps: SupportsMaps
  restaurantByProductMap: RestaurantByProductMap
  product: ProductDTO
  toggleOption: (optionCategoryId: string, optionId: string) => void
  sendToBasket: () => void
  url: string
  onClick?: () => void
  colSize?: any
  isTerminal: boolean
  productMap: ProductMap
  menuCategoryList: MenuCategoryDTO[]
  className: string
  isSaleModal: boolean
  isProductDay: boolean
  isProductWeek: boolean
  renderPromo: ReactNode
  weightMap: WeightMapDto
}

const ProductCard: React.FC<ProductCardProps> = ({
  selectedProduct,
  supportsMaps,
  toggleOption,
  sendToBasket,
  product,
  restaurantByProductMap,
  url,
  onClick,
  colSize,
  isTerminal,
  productMap,
  menuCategoryList,
  className,
  isSaleModal,
  isProductDay,
  isProductWeek,
  renderPromo,
  weightMap,
}): React.ReactElement => {
  const [isOpenProductRestaurant, setIsOpenProductRestaurant] = useState<boolean>(false)
  const [isOpenDeliveryDeny, setIsOpenDeliveryDeny] = useState<boolean>(false)
  const [isOpenDeliveryOnly, setIsOpenDeliveryOnly] = useState<boolean>(false)
  const renderContent: React.ReactElement = useMemo(() => {
    const {
      description,
      imageFileName,
      isDeliveryDeny,
      isDeliveryOnly,
      name,
      productId,
      labels,
      alias,
    } = product

    let weightText: string = null
    if (product) {
      weightText = getWeightWithOptions(selectedProduct, weightMap, 'active')
    }

    let price: number = selectedProduct.price
    let salePrice: number = null
    if (product.productVariants?.length > 0) {
      let productVariant: ProductVariantDTO = null
      for (let itemPV of product.productVariants) {
        if (itemPV.productVariantId === selectedProduct.productVariantId) {
          productVariant = itemPV
          break
        }
      }
      salePrice = productVariant?.salePrice
        ? productVariant.price - (productVariant.price - productVariant.salePrice)
        : null
    } else {
      salePrice = product.salePrice ? price - (price - product.salePrice) : null
    }

    return (
      <Col
        xl={colSize ? colSize.xl : 3}
        lg={colSize ? colSize.lg : 3}
        md={colSize ? colSize.md : 4}
        sm={colSize ? colSize.sm : 6}
        xs={colSize ? colSize.xs : 12}
        key={product.productId}
        data-product-id={product.productId}
        className={'d-flex product-list-item-col'}>
        <div
          className={
            'product-list-item ' +
            (isSaleModal ? 'mb-0 ' : isTerminal ? 'mb-x8' : '') +
            (!isSaleModal && isProductDay ? 'card-product-day ' : '') +
            (!isSaleModal && isProductWeek ? 'card-product-week ' : '') +
            (className ? className : '')
          }>
          <div className={'product-card-body'}>
            {isTerminal ? (
              <ProductCardBodyTerminal
                product={product}
                imageFileName={imageFileName}
                name={name}
                weight={weightText}
                labels={labels}
                onClick={onClick}
                renderPromo={renderPromo}
                isSaleModal={isSaleModal}
                weightMap={weightMap}
                sendToBasket={sendToBasket}
              />
            ) : (
              <>
                <ProductCardBody
                  isDeliveryDeny={isDeliveryDeny}
                  onOpenDeliveryDeny={() => setIsOpenDeliveryDeny(true)}
                  isDeliveryOnly={isDeliveryOnly}
                  onOpenDeliveryOnly={() => setIsOpenDeliveryOnly(true)}
                  onOpenProductRestaurant={() => setIsOpenProductRestaurant(true)}
                  isProductRestaurant={
                    restaurantByProductMap && !!restaurantByProductMap[productId]
                  }
                  imageFileName={imageFileName}
                  name={name}
                  description={description}
                  weight={weightText}
                  labels={labels}
                  href={`${url}/${alias}`}
                  isTerminal={isTerminal}
                  processProductItems={product.processProductItems}
                  productMap={productMap}
                  menuCategoryList={menuCategoryList}
                  renderPromo={renderPromo}
                  isSaleModal={isSaleModal}
                  weightMap={weightMap}
                />
                {selectedProduct && !!supportsMaps?.restrictionsMap && (
                  <ProductOptions
                    selectedProduct={selectedProduct}
                    restrictionsMap={supportsMaps.restrictionsMap}
                    toggleOption={toggleOption}
                  />
                )}
              </>
            )}
          </div>
          {!isTerminal && (
            <ProductCardFooter
              price={price}
              salePrice={salePrice}
              sendToBasket={sendToBasket}
              href={
                selectedProduct.product.type === MenuEntityTypeEnum.COMBO ? `${url}/${alias}` : null
              }
            />
          )}

          {isOpenProductRestaurant && !isTerminal && (
            <CardModal onClose={() => setIsOpenProductRestaurant(false)}>
              <ModalProductRestaurantContent
                restaurants={restaurantByProductMap && restaurantByProductMap[productId]}
              />
            </CardModal>
          )}
          {isOpenDeliveryDeny && !isTerminal && (
            <CardModal onClose={() => setIsOpenDeliveryDeny(false)}>
              <div className={'mb-secondary'}>
                <span>
                  Данный товар недоступен для доставки, но вы можете забрать его самостоятельно
                </span>
              </div>
            </CardModal>
          )}
          {isOpenDeliveryOnly && !isTerminal && (
            <CardModal onClose={() => setIsOpenDeliveryOnly(false)}>
              <div className={'mb-secondary'}>
                <span>Эксклюзивно для доставки. Данный товар недоступен для самовывоза.</span>
              </div>
            </CardModal>
          )}
        </div>
      </Col>
    )
  }, [
    className,
    colSize,
    isOpenDeliveryDeny,
    isOpenDeliveryOnly,
    isOpenProductRestaurant,
    isProductDay,
    isProductWeek,
    isSaleModal,
    isTerminal,
    menuCategoryList,
    onClick,
    product,
    productMap,
    renderPromo,
    restaurantByProductMap,
    selectedProduct,
    sendToBasket,
    supportsMaps.restrictionsMap,
    toggleOption,
    url,
    weightMap,
  ])

  return <>{renderContent}</>
}

export {ProductCard}
