import React, {useMemo} from 'react'
import {Col, COLORS, Icon} from 'grilnica-reactstrap'
import {checkCircleOutlineSVG} from '../../../../components/svg/SystemIcons'
import {getStaticUrl} from '../../../../../utils/static'
import {mdiCheckCircle} from '@mdi/js'

interface PromoCardProps {
  isPersonalCoupon: boolean
  isActive: boolean
  actionName?: string
  promoCode?: string
  fullSale: number
  onChangeSelectedDefinition: () => void
  isTerminal: boolean
}

const PromoCard: React.FC<PromoCardProps> = ({
  isPersonalCoupon,
  isActive,
  actionName,
  promoCode,
  fullSale,
  onChangeSelectedDefinition,
  isTerminal,
}): React.ReactElement => {
  const renderPromoCard = useMemo(() => {
    const promoCardCss =
      (isTerminal ? 'promo-card-terminal' : 'promo-card') +
      ' ' +
      (isActive ? 'promo-card-active' : '')
    return (
      <div className={promoCardCss} onClick={onChangeSelectedDefinition}>
        <img
          alt={isPersonalCoupon ? 'Промокод' : 'Скидка'}
          src={getStaticUrl(
            isPersonalCoupon ? '/img/promo-basket-img.svg' : '/img/sale-basket-img.svg',
          )}
          width={isTerminal ? 120 : 66}
          height={isTerminal ? 120 : 66}
          title={isPersonalCoupon ? 'Промокод' : 'Скидка'}
          className={'mr-x1'}
        />
        <div className={'promo-name-wrapper'}>
          <p className={'promo-name'}>
            {isPersonalCoupon ? `Промокод: ${promoCode}` : `${actionName}`}
          </p>
          <p className={'sale-size mb-0'}>Скидка {fullSale} &#8381; </p>
        </div>
        {isActive && (
          <Icon
            path={isTerminal ? mdiCheckCircle : checkCircleOutlineSVG}
            size={isTerminal ? 1.3335 : 1}
            color={isTerminal ? COLORS.greenLight : COLORS.green}
            className={'promo-active-indicator'}
          />
        )}
      </div>
    )
  }, [
    actionName,
    fullSale,
    isActive,
    isPersonalCoupon,
    isTerminal,
    onChangeSelectedDefinition,
    promoCode,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    return (
      <Col xs={9} sm={5} md={6}>
        {renderPromoCard}
      </Col>
    )
  }, [renderPromoCard])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {PromoCard}
