import React, {useMemo} from 'react'
import {
  Button,
  ButtonIcon,
  ButtonIconTerminal,
  Col,
  Divider,
  InputVertical,
  Loading,
  Row,
} from 'grilnica-reactstrap'
import {PromoCard} from './PromoCard'
import {deleteSVG, pencilSVG, ticketSVG} from '../../../../components/svg/SystemIcons'
import {ActionDefinition} from '../../../../../store/action/types/ActionDefinition'

interface BasketPromoBlockProps {
  localCoupon: string
  onChangeLocalCoupon: (value: string) => void
  isOpenInputCoupon: boolean
  onOpenInputCoupon: () => void
  coupon: string
  onChangeCoupon: () => void
  onChangeSelectedDefinition: (id: string) => void
  definitions: ActionDefinition[]
  isUpdateCheckActions: boolean
  isSuccessPersonalCoupon: boolean
  selectedCouponDefinitionId: string
  removeCoupon: () => void
  isTerminal: boolean
  openAddCouponModalTerminal: () => void
}

const BasketPromoBlock: React.FC<BasketPromoBlockProps> = ({
  localCoupon,
  onChangeLocalCoupon,
  isOpenInputCoupon,
  onOpenInputCoupon,
  coupon,
  onChangeCoupon,
  onChangeSelectedDefinition,
  definitions,
  isUpdateCheckActions,
  selectedCouponDefinitionId,
  isSuccessPersonalCoupon,
  removeCoupon,
  isTerminal,
  openAddCouponModalTerminal,
}): React.ReactElement => {
  const renderInputBlock: React.ReactElement = useMemo(() => {
    return (
      <div className={'enter-promo d-flex mb-x4 py-x2'}>
        <InputVertical
          name={'promoCode'}
          id={'promoCode'}
          type={'text'}
          value={localCoupon}
          isRequired={false}
          placeholder={'Введите промокод'}
          className={'w-50 mr-x4 mb-0'}
          isRounded
          onChange={(value: string) => {
            onChangeLocalCoupon(value)
          }}
        />
        <Button color={'primary'} isRoundedButton onClick={onChangeCoupon}>
          Добавить
        </Button>
      </div>
    )
  }, [localCoupon, onChangeCoupon, onChangeLocalCoupon])

  const renderButtonAddCouponBlock: React.ReactElement = useMemo(() => {
    if (isTerminal) {
      return (
        <div className={'enter-promo-terminal mb-x8 pb-x4'}>
          <Button
            className={''}
            isRoundedButton
            onClick={() => {
              openAddCouponModalTerminal()
            }}
            color={'light-gray'}
            size={'lg'}>
            {coupon ? coupon : 'Ввести промокод'}
          </Button>
        </div>
      )
    } else {
      return (
        <div className={'enter-promo d-flex py-x4 align-items-center justify-content-center mb-x4'}>
          <Button
            style={'text'}
            iconPosition={'left'}
            id={'button-enter-coupon'}
            size={'sm'}
            onClick={onOpenInputCoupon}
            color={'primary'}
            type={'button'}
            isRoundedButton
            iconPath={ticketSVG}>
            Ввести промокод
          </Button>
        </div>
      )
    }
  }, [coupon, isTerminal, onOpenInputCoupon, openAddCouponModalTerminal])

  const renderActiveCouponBlock: React.ReactElement = useMemo(() => {
    if (isTerminal) {
      return (
        <div className={'enter-promo-terminal mb-x8 pb-x4'}>
          <div className={'d-flex flex-row'}>
            <Button
              className={''}
              isRoundedButton
              onClick={() => {
                openAddCouponModalTerminal()
              }}
              color={'light-gray'}
              size={'lg'}>
              {coupon}
            </Button>
            <div className={'d-flex flex-row'}>
              <ButtonIconTerminal
                iconPath={pencilSVG}
                title={'Редактировать'}
                onClick={openAddCouponModalTerminal}
                isNoBorder
                isFilled
                className={'ml-x6'}
              />
              <ButtonIconTerminal
                iconPath={deleteSVG}
                title={'Удалить'}
                onClick={removeCoupon}
                isNoBorder
                isFilled
                className={'ml-x6'}
              />
            </div>
          </div>
          {!!coupon && isSuccessPersonalCoupon !== undefined && !isSuccessPersonalCoupon && (
            <p className={'promo-code-invalid-terminal mb-0'}>Данный промокод недействителен</p>
          )}
        </div>
      )
    } else {
      return (
        <div className={'enter-promo mb-x4 py-x2'}>
          <div className={'d-flex justify-content-between'}>
            <p className={'promo-code-caption mb-0'}>
              Промокод <span className={'promo-code'}>{coupon}</span>
            </p>
            <div className={'d-flex flex-row'}>
              <ButtonIcon
                iconPath={pencilSVG}
                isHoverArea
                title={'Редактировать'}
                className={'mr-secondary'}
                onClick={onOpenInputCoupon}
              />
              <ButtonIcon
                iconPath={deleteSVG}
                isHoverArea
                title={'Удалить'}
                onClick={removeCoupon}
              />
            </div>
          </div>

          {!!coupon && isSuccessPersonalCoupon !== undefined && !isSuccessPersonalCoupon && (
            <p className={'promo-code-invalid mb-0'}>Данный промокод недействителен</p>
          )}
        </div>
      )
    }
  }, [
    isTerminal,
    coupon,
    onOpenInputCoupon,
    removeCoupon,
    isSuccessPersonalCoupon,
    openAddCouponModalTerminal,
  ])

  const renderEnterPromoCodeBlock: React.ReactElement = useMemo(() => {
    if (isOpenInputCoupon && !isTerminal) {
      return renderInputBlock
    } else if (coupon) {
      return renderActiveCouponBlock
    } else {
      return renderButtonAddCouponBlock
    }
  }, [
    coupon,
    isOpenInputCoupon,
    isTerminal,
    renderActiveCouponBlock,
    renderButtonAddCouponBlock,
    renderInputBlock,
  ])

  const renderActionsList: React.ReactElement = useMemo(() => {
    let count: number = 0
    for (let definition of definitions || []) {
      count += definition.isCoupon ? 1 : 0
    }

    if (count === 0) {
      if (isUpdateCheckActions) {
        return <Loading size={isTerminal ? 'xl' : 'm'} className={'mb-x8'} />
      }
      return null
    }

    const items: React.ReactElement[] = definitions.map((definition) => {
      return (
        definition.isCoupon && (
          <PromoCard
            key={definition.actionDefinitionId}
            isPersonalCoupon={definition.isPersonalCoupon}
            isActive={definition.actionDefinitionId === selectedCouponDefinitionId}
            fullSale={definition.fullSale}
            actionName={definition.actionName}
            promoCode={definition.isPersonalCoupon ? definition?.coupons[0] : undefined}
            onChangeSelectedDefinition={() => {
              onChangeSelectedDefinition(definition.actionDefinitionId)
            }}
            isTerminal={isTerminal}
          />
        )
      )
    })

    return (
      <div>
        <p className={'caption-select-promo' + ' ' + (isTerminal ? 'is-terminal mb-x8' : 'mb-x3')}>
          {isTerminal ? 'Акции' : 'Выберите Акцию'}
        </p>
        <Row className={'promo-list' + ' ' + (isTerminal ? 'is-terminal' : '')}>{items}</Row>
        {isTerminal && <Divider className={'w-100 mb-x4'} />}
      </div>
    )
  }, [
    definitions,
    isTerminal,
    isUpdateCheckActions,
    onChangeSelectedDefinition,
    selectedCouponDefinitionId,
  ])

  const renderContent: React.ReactElement = useMemo(() => {
    if (isTerminal) {
      return (
        <div>
          {renderActionsList}
          {renderEnterPromoCodeBlock}
        </div>
      )
    } else {
      return (
        <Col md={{size: 6, offset: 3}}>
          {renderActionsList}
          {renderEnterPromoCodeBlock}
        </Col>
      )
    }
  }, [renderActionsList, renderEnterPromoCodeBlock, isTerminal])

  return <React.Fragment>{renderContent}</React.Fragment>
}

export {BasketPromoBlock}
