import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Slider} from 'grilnica-reactstrap'
import {SiteSliderDTO} from 'grilnica-store-share'
import {connectComponent} from '../../../../../../store/common'
import {State} from '../../../../../../store/ducks'
import {SliderItem} from 'grilnica-reactstrap/src/components/slider/Slider'
import {loadSiteSliderListByCityIdService} from '../../../../../../services/slider'
import {CONSTANTS} from '../../../../../../../constants'
import {bindErrorActions} from '../../../../../../store/ducks/error'

interface SiteSliderProps {
  cityId: string
  errorActions: any
}

const SiteSlider: React.FC<SiteSliderProps> = ({cityId, errorActions}): React.ReactElement => {
  const [sliderItemsList, setSliderItemsList] = useState<SliderItem[]>(null)

  const loadSliderItemsList = useCallback(
    async (cleanupFunction) => {
      if (cityId) {
        const sliderListDB: SiteSliderDTO[] = await loadSiteSliderListByCityIdService(
          cityId,
          errorActions,
        )
        if (sliderListDB && sliderListDB.length > 0) {
          let newSliderItemsList: SliderItem[] = sliderListDB.map((siteSlider) => {
            return {
              altText: siteSlider.altText,
              caption: '',
              link: !!siteSlider.link ? siteSlider.link : null,
              src: CONSTANTS.cdnUrl + '/' + siteSlider.imageFileName,
            }
          })

          if (!cleanupFunction) {
            setSliderItemsList(newSliderItemsList)
          }
        }
      }
    },
    [cityId, errorActions],
  )

  useEffect(() => {
    let cleanupFunction: boolean = false
    loadSliderItemsList(cleanupFunction)
    return () => {
      cleanupFunction = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderContent: React.ReactElement = useMemo(() => {
    if (sliderItemsList) {
      return (
        <Slider
          items={sliderItemsList}
          className={'mt-n-x2 w-100'}
          key={'slider'}
          id={'main-slider'}
        />
      )
    } else {
      return null
    }
  }, [sliderItemsList])

  return <>{renderContent}</>
}
export default connectComponent(
  (state: State) => ({
    cityId: state.city.selectedCity?.cityId,
  }),
  (dispatch) => ({
    errorActions: bindErrorActions(dispatch),
  }),
  SiteSlider,
)
